import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Home from '../pages/home';
import FindJobs from '../pages/find-jobs';
import FindTalent from '../pages/find-talent';
import Ebook from '../pages/ebook';
import AboutUs from '../pages/about-us';
import Result from '../pages/result';
import TermsOfUse from '../pages/terms-of-use';
import ResetPassword from '../pages/reset-password';
import ResetPasswordSuccess from '../pages/reset-password-success';
import PrivacyPolicy from '../pages/privacy-policy';
import ResumeGptHome from '../pages/resume-gpt/home';
import ResumeOverview from '../pages/resume-gpt/resume-overview';
import ResumeGptStart from '../pages/resume-gpt/start';
import ResumeGptSelectTemplate from '../pages/resume-gpt/select-template';
import JDGptHome from '../pages/jd-gpt/home';
import JDGptOverview from '../pages/jd-gpt/overview';

function PublicLayout() {
    return <>
        <Header />
        <Switch>
            <Route path='/jd-gpt'>
                <JDGptHome />
            </Route>
            <Route path='/jd-gpt/job-description/:id'>
                <JDGptOverview />
            </Route>
            <Route path='/resume-gpt/start' >
                <ResumeGptStart />
            </Route>
            <Route path='/resume-gpt/resume/:id' >
                <ResumeOverview />
            </Route>
            <Route path='/resume-gpt/select-template/:id' >
                <ResumeGptSelectTemplate />
            </Route>
            <Route path='/resume-gpt'>
                <ResumeGptHome />
            </Route>
            <Route path='/result/:id'>
                <Result />
            </Route>
            <Route path='/about-us'>
                <AboutUs />
            </Route>
            <Route path='/ebook'>
                <Ebook />
            </Route>
            <Route path='/find-jobs'>
                <FindJobs />
            </Route>
            <Route path='/find-talent'>
                <FindTalent />
            </Route>
            <Route path='/reset-password/:id'>
                <ResetPassword />
            </Route>
            <Route path='/reset-password-success'>
                <ResetPasswordSuccess />
            </Route>
            <Route path='/privacy-policy'>
                <PrivacyPolicy />
            </Route>
            <Route path='/terms-of-use'>
                <TermsOfUse />
            </Route>
            <Route path='/'>
                <Home />
            </Route>
        </Switch>
        <Footer />
    </>
}

export default PublicLayout;