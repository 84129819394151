import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Typography } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { APIs } from '../../utils/constants.js';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import Text from '../../components/text';
import Loading from '../../assets/images/loading.mp4';
import Button from '../../components/button';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { isDesktop } from '../../utils/window';

const ResumeGptSelectTemplate = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [resumeURL, setResumeURL] = useState(null);
    const scale = isDesktop() ? 1.0 : 0.5;

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }, []);

    const changePage = useCallback((offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }, []);

    const previousPage = useCallback(() => {
        changePage(-1);
    }, [changePage]);

    const nextPage = useCallback(() => {
        changePage(1);
    }, [changePage]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    useEffect(() => {
        const fetchResume = async () => {
            try {
                const response = await fetch(APIs.GENERATE_RESUME.replace(':id', id), { method: 'POST' });
                const res = await response.json();
                if (response.ok) {
                    setLoading(false);
                    setResumeURL(res.url);
                }
            } catch (error) {
                // console.error('Failed to fetch resume:', error);
            }
        };
        fetchResume();
    }, [id]);

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'resume-gpt::select-template::title-name' })}`}
                </title>
            </Helmet>
            {loading ? (
                <Row className='my-5 h-100' justify='center' align='middle'>
                    <div className='text-center'>
                        <video loop muted autoPlay playsInline src={Loading} className='mb-3' preload='auto' />
                        <Typography.Title level={3}>
                            <Text id='resume-gpt::select-template::text::loading' />
                        </Typography.Title>
                    </div>
                </Row>
            ) : (
                <Row className='my-5' justify='center'>
                    <Col>
                        <Document
                            file={resumeURL}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className='p-4 border rounded'
                        >
                            <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} scale={scale} />
                        </Document>
                        <Row className='mt-4' justify='center'>
                            <Col xs={24}>
                                <Typography.Paragraph className='text-center'>
                                    <Text id='resume-gpt::select-template::text::page' values={{
                                        current: () => pageNumber || (numPages ? 1 : '--'),
                                        total: () => numPages || '--'
                                    }} />
                                </Typography.Paragraph>
                                <Button
                                    type='primary'
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                    className='w-50 text-center'
                                    size='sm'
                                >
                                    <Text id='resume-gpt::select-template::button::previous' />
                                </Button>
                                <Button
                                    type='primary'
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                    className='w-50 text-center'
                                    size='sm'
                                >
                                    <Text id='resume-gpt::select-template::button::next' />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default ResumeGptSelectTemplate;
